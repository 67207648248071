export interface Plan {
  id: number;
  name: string;
  type: 'subscription' | 'message' | AddOnType | AdditionalModule;
  description?: string; // add in plan module
  limits: {
    user: number;
    channel: number;
    message: number | -1; // -1 means unlimited
    data_retention: number;
    flow: number | -1;
    workflow: number | -1;
    attribute: number | -1;
    tag: number | -1;
  };
  has_form: boolean;
  has_app: boolean;
  has_branding: boolean;
  has_broadcast: boolean;
  has_storage: boolean;
  meta: {
    created: Date;
    updated: Date;
  };
  prices: PlanPrice[];
}

export interface PlanPrice {
  id: number;
  duration_type: 'onetime' | 'month' | 'year' | 'quarter';
  ori_price_per_month: number;
  price_per_month: number;
  total_price: number;
  meta: {
    created: Date;
    updated: Date;
  };
}

export type AddOn = {
  type: AddOnType;
  quantity: number;
};

export enum AddOnType {
  USER = 'user',
  CHANNEL = 'channel',
  CALENDAR = 'calendar',
}

export enum AdditionalModule {
  BROADCAST = 'broadcast',
  APP = 'app',
  FORM = 'form',
  STORAGE = 'storage',
  BOOKING = 'booking',
}

export enum SubscriptionPlanType {
  BASIC = 'basic',
  STARTER = 'starter',
  PRO = 'pro',
  BUSINESS = 'business',
}

export interface CartBuilder {
  subscriptionPeriod: 'quarter' | 'year';
  subscriptionPlanType: SubscriptionPlanType;
  additionalModules: { plan_id: number; plan_type: string }[];
  addOns: AddOn[];
}

export interface CheckoutItem {
  plan_name: string;
  price_id: number;
  plan_type: string;
  quantity: number;
  price_per_month: number;
}
