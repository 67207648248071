import Channels from '@/components/Channels';

import ModalWithGraphic from '@/components/ModalWithGraphic';
import NoticeIconView from '@/components/NoticeIcon';
import useModal from '@/hooks/useModal';
import { Alert, Button, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import moment from 'moment';
import React from 'react';
import { Link, useModel } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';

dayjs.extend(isToday);

export type SiderTheme = 'light' | 'dark';

// const menu = [
//   {
//     key: '1',
//     icon: <img src="/images/img/pdf.png" width={22} />,
//     label: (
//       <a target="_blank" rel="noopener noreferrer" href="/LuluChat_System_User_Guide.pdf">
//         Read User Guide
//       </a>
//     ),
//   },
//   // {
//   //   key: '2',
//   //   icon: <img src='/images/img/tour-guide.png' width={22} />,
//   //   label: (
//   //     <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/luluchat/meeting">
//   //       Schedule 1 on 1 Coaching
//   //     </a>
//   //   ),
//   // },
// ];

const GlobalHeaderRight: React.FC = () => {
  const { isModalVisible, openModal, closeModal } = useModal();
  const {
    isModalVisible: isNoticeModalVisible,
    openModal: openNoticeModal,
    closeModal: closeNoticeModal,
  } = useModal();
  // const {
  //   isModalVisible: isAdditionalChannelModalVisible,
  //   openModal: openAdditionalChannelModal,
  //   closeModal: closeAdditionalChannelModal,
  // } = useModal();
  const { initialState } = useModel('@@initialState');

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }

  const getPlanLabel = (date: Date, plan: string) => {
    const noOfDay = dayjs().diff(date, 'days') - 7;
    if (plan === 'Free Trial') {
      if (noOfDay <= 0) {
        if (noOfDay === 0) {
          return <Tag color="red">Free Trial Expires Today</Tag>;
        }
        return <Tag color="#FEE11C">Free Trial Expires in {Math.abs(noOfDay)} days</Tag>;
      }
    }
    if (noOfDay > 0) {
      return (
        <Tag color="red">Plan Expired at {dayjs(date).add(7, 'days').format('YYYY-MM-DD')}</Tag>
      );
    }
    return null;
  };

  return (
    <>
      <Space className={className}>
        {/* <HeaderSearch
        className={`${styles.action} ${styles.search}`}
        placeholder="站内搜索"
        defaultValue="umi ui"
        options={[
          {
            label: <a href="https://umijs.org/zh/guide/umi-ui.html">umi ui</a>,
            value: 'umi ui',
          },
          {
            label: <a href="next.ant.design">Ant Design</a>,
            value: 'Ant Design',
          },
          {
            label: <a href="https://protable.ant.design/">Pro Table</a>,
            value: 'Pro Table',
          },
          {
            label: <a href="https://prolayout.ant.design/">Pro Layout</a>,
            value: 'Pro Layout',
          },
        ]} // onSearch={value => {
        //   console.log('input', value);
        // }}
      /> */}
        {/* <span
        className={styles.action}
        onClick={() => {
          window.open('https://pro.ant.design/docs/getting-started');
        }}
      >
        <QuestionCircleOutlined />
      </span> */}
        <div className={styles.noticeWrapper}>
          {moment().isBefore(moment('2024-08-11 06:00')) && (
            <Alert
              style={{ padding: '0px 8px' }}
              showIcon
              message={
                <Space>
                  <Typography>
                    <small>Scheduled Downtime Notice on August 11, 2024</small>
                  </Typography>
                  <Button onClick={openNoticeModal} type="link" size="small">
                    See Details
                  </Button>
                </Space>
              }
              type="warning"
            />
          )}
          <Modal
            onCancel={() => closeNoticeModal()}
            footer={false}
            open={isNoticeModalVisible}
            title="Scheduled Downtime Notice"
          >
            <Typography>
              Dear Customer,
              <br />
              <br />
              We will be performing a scheduled maintenance and upgrade for improved experience on
              our platform from <b>03:00 AM to 06:00 AM on August 11, 2024</b>.
              <br />
              <br />
              <b>Kindly Note</b>: All services on luluchat will be unavailable during this
              maintenance.
              <br />
              <br />
              We apologize for any inconvenience this may cause and your patience is highly
              appreciated.
              <br />
              <br />
              Thanks, <br />
              Team luluchat
            </Typography>
          </Modal>
        </div>
        <div className={styles.headerButtons}>
          <div className={styles.planLabelWrapper}>
            {initialState?.currentTeam?.limit?.limits?.message !== -1 &&
            initialState?.currentTeam?.limit?.limits?.message <=
              initialState?.currentTeam?.limit?.total_sent ? (
              <Tag color="red">Message limit reached</Tag>
            ) : (
              ''
            )}
            {getPlanLabel(
              initialState?.currentTeam?.limit?.billing?.end_date,
              initialState?.currentTeam?.limit?.billing?.plan?.name,
            )}
          </div>
          <Button
            type="primary"
            className={[styles.actionButton, styles.guideMeButton].join(' ')}
            size="small"
          >
            <a href="https://www.luluchat.io/tutorial" target="_blank" rel="noreferrer">
              Guide Me
            </a>
          </Button>
          <Link to="/plans">
            <Button
              type="text"
              className={styles.actionButton}
              icon={<img src="/images/svg/plan.svg" className={styles.icon} />}
              id="tour-step-plans"
            >
              <span className={styles.headerButtonLabel}>Plans</span>
            </Button>
          </Link>
          <Button
            type="text"
            className={styles.actionButton}
            onClick={openModal}
            icon={
              <img
                src={
                  initialState?.currentChannel?.status !== 'ready'
                    ? '/images/img/exclamation-mark.png'
                    : '/images/svg/phone.svg'
                }
                className={styles.icon}
              />
            }
            id="tour-step-channels"
          >
            {initialState?.currentChannel?.status !== 'ready' ? (
              <Tooltip title="Channel not connected">
                <div className={styles.headerButtonLabel}>
                  Channels
                  {initialState?.currentChannel?.contact_number
                    ? ` (${initialState?.currentChannel?.contact_number})`
                    : ''}
                </div>
              </Tooltip>
            ) : (
              <div className={styles.headerButtonLabel}>
                Channels{' '}
                {initialState?.currentChannel?.contact_number
                  ? `(${initialState?.currentChannel?.contact_number})`
                  : ''}
              </div>
            )}
            <div className={styles.appVersion}>App Version: {COMMIT_HASH}</div>
          </Button>
        </div>
        <NoticeIconView />

        <Avatar menu />
        {/* <SelectLang className={styles.action} /> */}
      </Space>
      <ModalWithGraphic
        title="Channels"
        icon="/images/svg/channel.svg"
        open={isModalVisible}
        onCancel={closeModal}
        iconBgColor="#D9F5F7"
      >
        {isModalVisible ? <Channels /> : null}
      </ModalWithGraphic>
      {/* <ModalWithGraphic
        title="Purchase Additional Channels"
        icon='/images/svg/channel.svg'
        open={isAdditionalChannelModalVisible}
        onCancel={closeAdditionalChannelModal}
        iconBgColor="#D9F5F7"
      >
        {isAdditionalChannelModalVisible ? <AdditionalChannels onCloseModal={closeAdditionalChannelModal} /> : null}
      </ModalWithGraphic> */}
    </>
  );
};

export default GlobalHeaderRight;
